var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.invoiceIsFetchingPayment
        ? _c(
            "v-row",
            { staticClass: "pb-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      absolute: "",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(
        _vm.invoiceDetailsPayment?.invoicePayments,
        function (invoicePayment, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-start align-center",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold title" },
                            [
                              _vm._v(
                                _vm._s(_vm.fundingSourceLabel) +
                                  " #" +
                                  _vm._s(index + 1)
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: "11" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "body-3 grey--text text--darken-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.fundingSourceLabel) +
                                        ": " +
                                        _vm._s(
                                          invoicePayment.fundingSourceName ||
                                            "None"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "body-3 grey--text text--darken-1",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" Budget Code: "),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        invoicePayment.isBudgetCodeDeleted
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      left: "",
                                                                      color:
                                                                        "red",
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-alert-circle "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              invoicePayment.isBudgetCodeDeleted
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.removedBudgetCodeMessage
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            invoicePayment.budgetCode || "N/A"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex payment-row",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row align-center" },
                            [
                              invoicePayment.fundingSourceType === "percentage"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-3 funding-source-type",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                invoicePayment.amount + "%"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-text-field",
                                        _vm._b(
                                          {
                                            ref: "amountField_" + index,
                                            refInFor: true,
                                            staticClass: "amount-field mx-2",
                                            attrs: {
                                              disabled:
                                                _vm.isInvoicePaymentReadOnly(
                                                  invoicePayment
                                                ),
                                              placeholder: "0.00",
                                              prefix: "$",
                                              outlined: "",
                                              readonly: "",
                                              value: _vm.percentageValue({
                                                amount: invoicePayment.amount,
                                                total:
                                                  _vm.invoiceTotalCosts.total,
                                              }),
                                            },
                                          },
                                          "v-text-field",
                                          _vm.inputProps,
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-3 funding-source-type",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.convertSnakeCaseToNormalCase(
                                                  invoicePayment.fundingSourceType
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-text-field",
                                        _vm._b(
                                          {
                                            ref: "amountField_" + index,
                                            refInFor: true,
                                            staticClass: "amount-field mx-2",
                                            attrs: {
                                              disabled:
                                                _vm.isInvoicePaymentReadOnly(
                                                  invoicePayment
                                                ),
                                              placeholder: "0.00",
                                              prefix: "$",
                                              label: "Amount",
                                              type: "number",
                                              value: invoicePayment.amount,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onChangeOfAmount(
                                                  $event,
                                                  invoicePayment
                                                )
                                              },
                                            },
                                          },
                                          "v-text-field",
                                          _vm.inputProps,
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  ),
                              _vm.isEditable
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editPayment(invoicePayment)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-end",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                ref: "checkNumberField_" + index,
                                refInFor: true,
                                staticClass: "check-number-field mx-2",
                                attrs: {
                                  disabled:
                                    !_vm.canEditCheckNumber(invoicePayment),
                                  type: "text",
                                  label: "Check Number",
                                  value: invoicePayment.checkNumber,
                                  maxlength: "10",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateInvoicePayment({
                                      ...invoicePayment,
                                      checkNumber: $event,
                                    })
                                  },
                                },
                              },
                              "v-text-field",
                              _vm.inputProps,
                              false
                            )
                          ),
                          _c(
                            "v-text-field",
                            _vm._b(
                              {
                                ref: "batchJournalNumberField_" + index,
                                refInFor: true,
                                staticClass: "batch-journal-number-field mx-2",
                                attrs: {
                                  disabled: !_vm.canEditBatchJournalNumber,
                                  type: "text",
                                  label: "Batch/Journal #",
                                  value: invoicePayment.batchJournalNumber,
                                  maxlength: "15",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateInvoicePayment({
                                      ...invoicePayment,
                                      batchJournalNumber: $event,
                                    })
                                  },
                                },
                              },
                              "v-text-field",
                              _vm.inputProps,
                              false
                            )
                          ),
                          _c(
                            "div",
                            { staticClass: "mx-2" },
                            [
                              _c("ChipsDropdown", {
                                attrs: {
                                  items:
                                    _vm.filteredInvoiceStatusOptions(
                                      invoicePayment
                                    ),
                                  canUpdate: () =>
                                    _vm.canUpdateInvoicePaymentStatus(
                                      invoicePayment
                                    ),
                                  statusConfig: _vm.statusConfig,
                                  value: invoicePayment.status,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChipStatusSelect(
                                      $event,
                                      invoicePayment
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.invoiceReadOnly
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    text: "",
                                    small: "",
                                    color: "red",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteInvoicePayment(
                                        invoicePayment,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-delete"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "divider mb-3" }),
            ],
            1
          )
        }
      ),
      _c(
        "v-row",
        { staticClass: "pt-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _vm.canAddMore
                ? _c(
                    "v-btn",
                    {
                      attrs: { plain: "" },
                      on: { click: _vm.addFundingSource },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.fundingSourceLabel) + " "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end pr-5", attrs: { cols: "8" } },
            [_vm._v("Total:")]
          ),
          _c("v-col", { attrs: { cols: "1" } }, [
            _vm._v("$ " + _vm._s(parseFloat(_vm.total).toFixed(2))),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pa-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-space-between",
              attrs: { cols: "12" },
            },
            [
              _vm.canSetDoNotBill
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "red" },
                      on: { click: _vm.voidInvoice },
                    },
                    [_vm._v(" Do Not Bill ")]
                  )
                : _vm._e(),
              _vm.selectedInvoice.status == "do_not_bill"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "green" },
                      on: { click: _vm.activateInvoice },
                    },
                    [_vm._v(" Activate Invoice ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    color: "blue",
                    disabled: _vm.invoiceReadOnly || !_vm.isFullPayment,
                  },
                  on: { click: _vm.onStatusClick },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.paymentStatus.text === "Pending"
                          ? "Approve Funding Sources"
                          : "Approved"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addFundingSourceDialog.visible
        ? _c("InvoiceDetailsPaymentFundingSourceDialog", {
            attrs: {
              visible: _vm.addFundingSourceDialog.visible,
              data: _vm.editPaymentData,
              invoiceId: _vm.invoiceId,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.addFundingSourceDialog, "visible", $event)
              },
            },
          })
        : _vm._e(),
      _c("CustomFormFieldsDisplay", {
        staticClass: "pt-4",
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "Payment",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }