var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: { value: _vm.visible },
    on: { input: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(" Edit Travel Cost Details ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "formEditTravelCost",
                on: { submit: _vm.saveTravelCost },
                model: {
                  value: _vm.form.valid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "valid", $$v)
                  },
                  expression: "form.valid",
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm.fields.some(
                      (field) => field.value === "date" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c(
                              "DatePicker",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.date,
                                      _vm.rules.minDate(
                                        _vm.dateValidationDetails.minimumDate,
                                        _vm.dateValidationDetails
                                          .dateStringMessage
                                      ),
                                      _vm.rules.maxDate(
                                        _vm.dateValidationDetails.maximumDate,
                                        _vm.dateValidationDetails
                                          .dateStringMessage
                                      ),
                                      _vm.rules.allowedDates(
                                        _vm.travelCostDates
                                      ),
                                    ],
                                    label: "Date",
                                  },
                                  model: {
                                    value: _vm.form.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "date", $$v)
                                    },
                                    expression: "form.date",
                                  },
                                },
                                "DatePicker",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) => field.value === "pickUp" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.decimal,
                                    ],
                                    label: "Pick Up (mi)",
                                    type: "number",
                                    hint: _vm.pickUpMilesHint,
                                  },
                                  model: {
                                    value: _vm.form.pickUp,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "pickUp", $$v)
                                    },
                                    expression: "form.pickUp",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) =>
                        field.value === "startOdometer" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.numeric,
                                      _vm.rules.min(0),
                                      _vm.rules.max(_vm.form.endOdometer),
                                      _vm.rules.decimal,
                                    ],
                                    label: "Start Odometer",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.startOdometer,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "startOdometer", $$v)
                                    },
                                    expression: "form.startOdometer",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) =>
                        field.value === "endOdometer" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.numeric,
                                      _vm.rules.min(_vm.form.startOdometer),
                                      _vm.rules.decimal,
                                    ],
                                    label: "End Odometer",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.endOdometer,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "endOdometer", $$v)
                                    },
                                    expression: "form.endOdometer",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) =>
                        field.value === "milesPerGallon" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.numeric,
                                      _vm.rules.min(1),
                                      _vm.rules.decimal,
                                    ],
                                    label: "Miles per Gallon",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.milesPerGallon,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "milesPerGallon", $$v)
                                    },
                                    expression: "form.milesPerGallon",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) => field.value === "fuelUsed" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.numeric,
                                      _vm.rules.min(0.01),
                                      _vm.rules.decimal,
                                    ],
                                    label: "Fuel (gal)",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.fuelUsed,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fuelUsed", $$v)
                                    },
                                    expression: "form.fuelUsed",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) => field.value === "fuelRate" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.numeric,
                                      _vm.rules.min(0),
                                      _vm.rules.decimal,
                                    ],
                                    label: "Fuel Rate",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.fuelRate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fuelRate", $$v)
                                    },
                                    expression: "form.fuelRate",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) => field.value === "mileage" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.decimal,
                                    ],
                                    label: "Mileage",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.mileage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "mileage", $$v)
                                    },
                                    expression: "form.mileage",
                                  },
                                },
                                "v-text-field",
                                {
                                  ..._vm.inputProps,
                                  readonly: _vm.hasOdometerReading(_vm.form),
                                  disabled: _vm.hasOdometerReading(_vm.form),
                                },
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) =>
                        field.value === "mileageRate" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.decimal,
                                    ],
                                    label: "Mileage Rate",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.mileageRate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "mileageRate", $$v)
                                    },
                                    expression: "form.mileageRate",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) =>
                        field.value === "mileageRate" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.decimal,
                                    ],
                                    label: "Additional Cost Per Mile",
                                    type: "number",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.form.additionalMileageCost,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "additionalMileageCost",
                                        $$v
                                      )
                                    },
                                    expression: "form.additionalMileageCost",
                                  },
                                },
                                "v-text-field",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fields.some(
                      (field) => field.value === "total" && !field.disabled
                    )
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "v-text-field",
                              _vm._b(
                                {
                                  attrs: {
                                    label: "Total Cost",
                                    value: _vm.totalCost,
                                    type: "number",
                                  },
                                },
                                "v-text-field",
                                {
                                  ..._vm.inputProps,
                                  readonly: true,
                                  disabled: true,
                                },
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: !_vm.form.valid,
                  loading: _vm.invoiceIsFetchingTravelCost,
                  color: "success",
                },
                on: { click: _vm.saveTravelCost },
              },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("mdi-content-save"),
                ]),
                _vm._v(" Save "),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }